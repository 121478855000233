import { buildSearchEngine } from '@coveo/headless';

let cacheHeadlessEngine = {};

export const getHeadlessEngine = (name, configuration, preprocessRequest) => {
    if (cacheHeadlessEngine[name]) {
        return cacheHeadlessEngine[name];
    }

    const { renewAccessTokenUrl } = configuration;

    const headlessEngine = buildSearchEngine({
        configuration: {
            ...configuration,
            renewAccessToken: renewAccessTokenUrl
                ? () =>
                      fetch(renewAccessTokenUrl)
                          .then((r) => r.json())
                          .then((r) => r.token)
                : undefined,
        },
    });

    // const context = buildContext(headlessEngine);
    // context.add('website', 'engineering');

    cacheHeadlessEngine[name] = headlessEngine;

    return headlessEngine;
};
