import PropTypes from 'prop-types';
import React from 'react';
import { Item } from 'react-stately';

import * as customPropTypes from '@/custom-prop-types';
import { LazyIconArrowRightCircle } from '@/design-system/atoms/Icons/IconArrowRightCircle/Lazy';
import Typography from '@/design-system/atoms/Typography';
import { regExpEscape } from '@/utils/regExpEscape';

import { ComboBox } from '../ComboBox';

const emptyArray = [];

/**
 * Main Typeahead component.
 *
 * This component uses [`useComboBoxState`](https://react-spectrum.adobe.com/react-stately/useComboBoxState.html)
 * from 'react-stately' and [`useComboBox` ](https://react-spectrum.adobe.com/react-aria/useComboBox.html)
 * from 'react-aria'.
 *
 * Other than the custom props listed below this component access all `useComboBox` arguments as prop.
 *
 * Example of how to watch for changes:
 *
 * ```
 * const TypeaheadStory = (props) => {
 *    const [open, setOpen] = React.useState(false);
 *    const [inputValue, setInputValue] = React.useState('');
 *    const [selectedId, setSelectedId] = React.useState(null);
 *    const [selectedItem, setSelectedItem] = React.useState(null);
 *
 *    return (
 *        <>
 *            <pre style={{ marginBottom: 32 }}>
 *                {JSON.stringify({ open, inputValue, selectedId, selectedItem }, null, 4)}
 *            </pre>
 *            <Typeahead
 *                {...props}
 *                inputValue={inputValue}
 *                onOpenChange={(newOpen) => setOpen(newOpen)}
 *                onInputChange={(newValue) => setInputValue(newValue)}
 *                onSelectionChange={(newSelectedId) => setSelectedId(newSelectedId)}
 *                onSelectionItemChange={(newSelectedItem) => setSelectedItem(newSelectedItem)}
 *            />
 *        </>
 *    );
 * };
 * ```
 */
export const Typeahead = React.forwardRef((props, ref) => {
    const {
        label,
        iconComponent,
        buttonProps,
        items = emptyArray,
        fixedItem = {},
        defaultInputValue,
        inputValue,
        inputType,
        onInputChange,
        onSelectionChange,
        onSelectionItemChange,
        onBlur,
        onKeyDown,
        debug,
        variant,
        className,
    } = props;
    const uncontrolled = onInputChange == null;

    const [internalInputValue, setInternalInputValue] = React.useState(defaultInputValue ?? '');

    const handleInputChange = React.useCallback(
        (v) => {
            if (onInputChange) {
                onInputChange(v);
            } else {
                setInternalInputValue(v);
            }
        },
        [onInputChange],
    );

    const finalInputValue = (uncontrolled ? internalInputValue : inputValue) ?? '';

    const handleSelectionChange = React.useCallback(
        (id) => {
            onSelectionChange?.(id);
            onSelectionItemChange?.(items.find((i) => `${i.id}` === id) ?? null);
        },
        [items, onSelectionChange, onSelectionItemChange],
    );

    const inputValueBeforeKeyDownRef = React.useRef();
    const latestKeyDownRef = React.useRef();

    const handleComboBoxBlur = React.useCallback(
        (e) => {
            if (latestKeyDownRef.current === 'Tab') {
                handleInputChange(inputValueBeforeKeyDownRef.current);
            }

            onBlur?.(e);
        },
        [handleInputChange, onBlur],
    );

    const handleComboBoxKeyDown = React.useCallback(
        (e) => {
            latestKeyDownRef.current = e.key;
            inputValueBeforeKeyDownRef.current = e.target.value;

            onKeyDown?.(e);
        },
        [onKeyDown],
    );

    return (
        <ComboBox
            ref={ref}
            {...props}
            label={label}
            items={items}
            inputValue={finalInputValue}
            onInputChange={handleInputChange}
            onSelectionChange={handleSelectionChange}
            menuTrigger="manual"
            allowsCustomValue
            hasFixedItem={!!fixedItem?.label}
            iconComponent={iconComponent}
            buttonProps={buttonProps}
            onBlur={handleComboBoxBlur}
            onKeyDown={handleComboBoxKeyDown}
            debug={debug}
            variant={variant}
            className={className}
            type={inputType}
        >
            {items.map((item) => (
                <Item key={item.id ?? item.text} textValue={item.text}>
                    <Typography
                        variant={Typography.VARIANT.TB2}
                        lineClamp={1}
                        dangerouslySetInnerHTML={{
                            __html: item.text.replace(
                                new RegExp(`(${regExpEscape(finalInputValue)})`, 'i'),
                                `<b>$1</b>`,
                            ),
                        }}
                    />
                </Item>
            ))}
            {!!fixedItem?.label && (
                <Item
                    key={'fixed-item'}
                    textValue={fixedItem.label}
                    href={fixedItem.href}
                    target={fixedItem.target}
                    csr={fixedItem.csr}
                >
                    <Typography variant={Typography.VARIANT.CTA1} content={fixedItem.label} />
                    <LazyIconArrowRightCircle size="medium" />
                </Item>
            )}
        </ComboBox>
    );
});

Typeahead.propTypes = {
    /**
     * Input Label
     */
    label: PropTypes.string,
    /**
     * Input Icon
     */
    iconComponent: PropTypes.elementType,
    /**
     * List of items/suggestions
     */
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            text: PropTypes.string,
        }),
    ),
    /**
     * Fixed link
     */
    fixedItem: customPropTypes.linkPropType,
    /**
     * Default input value for uncontrolled component
     */
    defaultInputValue: PropTypes.string,
    /**
     * Input value for controlled component
     */
    inputValue: PropTypes.string,
    /**
     * `onInputChange` for controlled component
     */
    onInputChange: PropTypes.func,
    /**
     * This function is triggered every time that the selected ID change
     */
    onSelectionChange: PropTypes.func,
    /**
     * This function is triggered every time that the selected ITEM change
     */
    onSelectionItemChange: PropTypes.func,
    /**
     * If `true` adds a button to open the dropdown and keep it opened
     */
    debug: PropTypes.bool,
};
