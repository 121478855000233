import { DismissButton, Overlay, usePopover } from '@react-aria/overlays';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Popover.module.scss';

/**
 * Typeahead Popover component, it is responsible to handle the dropdown list.
 *
 * It isn't recommended to use this component out of the ComboBox component,
 * because it depends of values returned from the `useComboBoxState` and `useComboBox` hooks.
 */
export const Popover = (props) => {
    const { popoverRef, state, children, width, className } = props;

    const { popoverProps } = usePopover(
        {
            ...props,
            popoverRef,
        },
        state,
    );

    return (
        <Overlay>
            <motion.div
                initial={{ y: -10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                {...popoverProps}
                ref={popoverRef}
                className={classNames(styles['popover'], className)}
                style={{ width }}
            >
                {children}
                <DismissButton onDismiss={state.close} />
            </motion.div>
        </Overlay>
    );
};

Popover.propTypes = {
    /**
     * popoverRef from `useComboBox`
     */
    popoverRef: PropTypes.object,
    /**
     * state from `useComboBoxState`
     */
    state: PropTypes.object,
    /**
     * Input field width, so the popover will have the same width
     */
    width: PropTypes.number,
};
